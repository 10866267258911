import queryString from 'query-string';

import { APP_VERSION } from '../config';

import auth from './Authorization';
import { defaultLanguage, languageToApiHeader } from './Language';
import { Logger } from './Logger';
import { isPagingResponse, type PagingResponse, parsePage } from './Paging';

export const ORIGIN = (() => {
  if (typeof window !== 'undefined' && window.location) {
    return window.location.origin;
  }

  return process.env.NODE_ENV === 'production' ? 'https://hoppygo.com' : 'https://web.staging.hoppygo.com';
})();

export interface RequestConfig<D = UnsafeAny> {
  baseURL: string;
  headers: Record<string, string>;
  params?: Record<string, string | number | boolean | Array<string | number | boolean>>;
  data?: D;
  requiresAuth?: boolean;
  catchMessages?: string[];
  catchAllMessages?: boolean;
  ignoredGuards?: string[];
  abortSignal?: AbortSignal;
}

async function request<D = UnsafeAny>(
  url: string,
  method: string,
  config: Partial<RequestConfig<D>>,
): Promise<Response> {
  url = `${config.baseURL}${url}`;
  if (config.params && Object.keys(config.params).length) {
    url += `?${queryString.stringify(config.params)}`;
  }
  config.headers ??= {};
  config.headers['X-App-Version'] = APP_VERSION;
  config.headers['X-App-Token'] = process.env.GATSBY_XAPP_TOKEN;
  config.headers['X-Api-Key'] = process.env.GATSBY_API_KEY;
  config.headers['Accept-Language'] = languageToApiHeader(Api.language);
  if (!config.headers['Content-Type'] && !(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }

  if (auth.hasToken() || config.requiresAuth) {
    const accessToken = await auth.getAccessToken(config.requiresAuth);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return fetch(url, {
    method,
    headers: config.headers as Record<string, string>,
    body: config.data ? (config.data instanceof FormData ? config.data : JSON.stringify(config.data)) : null,
    signal: config.abortSignal,
  });
}

export class Api {
  public static language: string = defaultLanguage;
  private static log = new Logger('API');
  private readonly baseURL: string;

  constructor(apiVersion = 'v3') {
    this.baseURL = `/api/${apiVersion}`;
  }

  public get<T, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    url: string,
    params: UnsafeAny = {},
    options: Partial<RequestConfig> = {},
  ): Promise<ApiResponse<T, O>> {
    return this.request('GET', url, params, null, options);
  }

  public post<T, D = UnsafeAny, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    url: string,
    data: D = undefined,
    params: UnsafeAny = {},
    options: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    return this.request('POST', url, params, data, options);
  }

  public patch<T, D = UnsafeAny, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    url: string,
    data: D = undefined,
    params: UnsafeAny = {},
    options: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    return this.request('PATCH', url, params, data, options);
  }

  public put<T, D = UnsafeAny, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    url: string,
    data: D = undefined,
    params: UnsafeAny = {},
    options: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    return this.request('PUT', url, params, data, options);
  }

  public delete<T, D = UnsafeAny, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    url: string,
    data: D = undefined,
    params: UnsafeAny = {},
    options: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    return this.request('DELETE', url, params, data, options);
  }

  public head<T>(url: string, params: UnsafeAny = {}, options: Partial<RequestConfig> = {}): Promise<ApiResponse<T>> {
    return this.request('HEAD', url, params, null, options);
  }

  public document<T>(
    method: string,
    url: string,
    params: UnsafeAny = {},
    options: Partial<RequestConfig> = {},
  ): Promise<ApiResponse<T>> {
    return this.request(method, url, params, null, options);
  }

  protected async request<
    T,
    D = UnsafeAny,
    O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>,
  >(
    method: string,
    url: string,
    params: UnsafeAny = {},
    data: D = undefined,
    config: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    try {
      return await this.sendRequest(method, url, params, data, config);
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new ApiAbortError(method, url, params, data, config);
      }
      if (error instanceof ApiResponse) {
        throw error;
      }
      if (error instanceof Response) {
        if (error.status === 401 && auth.hasToken()) {
          await auth.refresh();
          return await this.sendRequest(method, url, params, data, config);
        }
        let originalData;
        try {
          originalData = await error.json();
        } catch {
          // ignore
        }
        if (error.status === 403) {
          auth.destroy();
          throw new ApiError(error, originalData);
        }
        throw new ApiError(error, originalData);
      }
      throw error;
    }
  }

  private async sendRequest<
    T,
    D = UnsafeAny,
    O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>,
  >(
    method: string,
    url: string,
    params: UnsafeAny = {},
    data: D = undefined,
    config: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    if (url.includes(this.baseURL)) {
      url = url.replace(this.baseURL, '');
    }

    const requestConfig: RequestConfig<D> = {
      baseURL: this.baseURL,
      params,
      headers: config.headers,
      data,
    };

    const axiosResponse = await request(url, method, requestConfig);
    Api.log.debug(`${method.toUpperCase()} ${axiosResponse.url}`, {
      status: axiosResponse.status,
    });

    if (!axiosResponse.ok) throw axiosResponse;

    let body = null;
    try {
      if (axiosResponse.status !== 204) {
        body = await axiosResponse.json();
      }
    } catch {
      // ignore
    }
    const response = new ApiResponse(axiosResponse, body);
    if (config.catchAllMessages) {
      response.findAnyMessage();
      if (response.message) {
        return Promise.reject(response);
      }
    }
    if (config.catchMessages) {
      response.findMessage(...config.catchMessages);
      if (response.message) {
        return Promise.reject(response);
      }
    }
    // @ts-ignore
    return response as ApiResponse<T>;
  }
}

class PrivateApi extends Api {
  protected request<T, D = UnsafeAny, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>>(
    method: string,
    url: string,
    params: UnsafeAny = {},
    data: D = undefined,
    config: Partial<RequestConfig<D>> = {},
  ): Promise<ApiResponse<T, O>> {
    return super.request(method, url, params, data, {
      ...config,
      requiresAuth: true,
    });
  }
}

interface ApiMessage {
  type: 'blocker' | 'validation_error';
  message: string;
  label: string;
}

export interface OriginalShared {
  success: boolean;
  messages: ApiMessage[];
}

export interface OriginalResponse<T> extends OriginalShared {
  data: T;
}

function isApiResponse<T>(response: OriginalResponse<T> | OriginalShared): response is OriginalResponse<T> {
  return typeof response === 'object' && 'data' in response && response.data !== undefined && response.data !== null;
}

function isPagingApiResponse<T>(
  response: OriginalResponse<PagingResponse<T>> | OriginalShared,
): response is OriginalResponse<PagingResponse<T>> {
  return isApiResponse<PagingResponse<T>>(response) && isPagingResponse(response.data);
}

export interface OriginalErrorResponse extends OriginalShared {
  error: {
    message: string;
  };
  message?: string;
}

export class ApiResponse<T, O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>> {
  public data: T;
  public status: number;

  constructor(public readonly response: Response, public originalData: O) {
    this.status = response.status ?? 500;
    if (originalData && isApiResponse(originalData)) {
      if (isPagingApiResponse(originalData)) {
        this.data = parsePage(originalData.data) as unknown as T;
      } else {
        this.data = originalData.data;
      }
    } else {
      this.data = originalData as unknown as T;
    }
  }

  protected _message: string;

  public get message(): string {
    return this.getMessage();
  }

  protected get messages(): ApiMessage[] {
    return this.originalData?.messages ?? [];
  }

  public findMessage(...availableTypes: string[]): void {
    if (!this.messages.length) return;
    for (const { message, label } of this.messages) {
      if (availableTypes.includes(message.replace('api.guard.', ''))) {
        this._message = label;
        return;
      }
    }
  }

  public findValidationError(): void {
    if (!this.messages.length) return;
    this._message = this.messages.find(m => m.type === 'validation_error')?.label;
  }

  public findAnyMessage(): void {
    if (!this.messages.length) return;
    this._message = this.messages[0].label;
  }

  public findMessageNotIn(...types: string[]): void {
    if (!this.messages.length) return;
    for (const { message, label } of this.messages) {
      if (!types.includes(message.replace('api.guard.', ''))) {
        this._message = label;
        return;
      }
    }
  }

  public hasMessage(...types: string[]): boolean {
    if (!this.messages.length) return false;
    for (const { message } of this.messages) {
      if (types.includes(message.replace('api.guard.', ''))) {
        return true;
      }
    }
    return false;
  }

  public getGuardNotIn(...ignoredGuards: string[]): string {
    this.findMessageNotIn(...ignoredGuards);
    const result = this._message;
    this._message = null;
    return result;
  }

  public getGuard(): string;

  public getGuard(...onlyGuards): string {
    if (Array.isArray(onlyGuards)) {
      this.findMessage(...onlyGuards);
    } else {
      this.findAnyMessage();
    }
    const result = this._message;
    this._message = null;
    return result;
  }

  public getValidationMessage(): string {
    this.findValidationError();
    const result = this._message;
    this._message = null;
    return result;
  }

  protected getMessage(): string {
    if (this._message) return this._message;
    if (this.messages) {
      const validationMessage = this.messages.find(it => it.type === 'validation_error');
      if (validationMessage) return validationMessage.label;
    }
    return null;
  }
}

export class ApiError<T = UnsafeAny, O extends OriginalErrorResponse = OriginalErrorResponse> extends ApiResponse<
  T,
  O
> {
  constructor(response: Response, originalData?: O) {
    super(response, originalData);
  }

  public get message(): string {
    return super.getMessage() ?? this.originalData?.message ?? this.originalData?.error?.message;
  }
}

export class ApiAbortError<
  T,
  D = UnsafeAny,
  _O extends OriginalResponse<UnsafeAny> | OriginalShared = OriginalResponse<T>,
> extends Error {
  constructor(
    public readonly method: string,
    public readonly url: string,
    public readonly params: UnsafeAny = {},
    public readonly data: D = undefined,
    public readonly config: Partial<RequestConfig<D>> = {},
  ) {
    super('Request aborted');
  }
}

export const PublicApiV3 = new Api('v3');
export const PrivateApiV3 = new PrivateApi('v3');
